import { useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import RestoreIcon from "@mui/icons-material/Restore";
import { Box, Button, Checkbox, Typography } from "@mui/material";

import useSnackbar from "@sellernote/_shared/src/hooks/admin/useSnackbar";
import TRELLO_BID_QUERY, {
  TRELLO_BID_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/admin/TRELLO_BID_QUERY";
import { PartnerBusinessArea } from "@sellernote/_shared/src/types/forwarding/partnerManagement";
import { TrelloBidDetail } from "@sellernote/_shared/src/types/forwarding/trello";
import { formatDate } from "@sellernote/_shared/src/utils/common/date";
import { toThousandUnitFormat } from "@sellernote/_shared/src/utils/common/number";
import { changePurchaseDomainToKr } from "@sellernote/_shared/src/utils/forwarding/admin/trello";

import Modal from "../../../../../../components/Modal";
import Table from "../../../../../../components/Table";

import HEAD_CELL_LIST from "./HEAD_CELL_LIST";

type Payable = {
  id: number;
  type: PartnerBusinessArea;
};

const INLAND_DOMAIN_LIST = ["inland", "parcel"];

export default function RestoreAccountPayableModal({
  trelloDetail,
  isOpen,
  onClose,
}: {
  trelloDetail: TrelloBidDetail;
  isOpen: boolean;
  onClose: () => void;
}) {
  const { handleSnackbarOpen: showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const managementCardDetailQueryKey = TRELLO_BID_QUERY_KEY_GEN.trelloDetail();

  const {
    id: shipmentId,
    accountPayables: accountPayableList,
    isImport,
    zoneId,
    startAddress,
    endAddress,
  } = trelloDetail;

  const [restorePayableList, setRestorePayableList] = useState<Payable[]>([]);

  const {
    data: deletedAccountPayableList = [],
    ResponseHandler: ResponseHandlerOfGetDeletedAccountPayable,
  } = TRELLO_BID_QUERY.useGetDeletedAccountPayable({ shipmentId });

  const {
    mutate: restorePayable,
    ResponseHandler: ResponseHandlerOfRestoreAccountPayable,
  } = TRELLO_BID_QUERY.useRestoreAccountPayable();

  const hasDestination = (() => {
    const isRestoringInland = restorePayableList.some(
      ({ type }) => type === "inland" || type === "parcel"
    );

    if (!isRestoringInland) return true;

    return zoneId && (isImport ? endAddress : startAddress);
  })();

  /** 매입관리에 세관이 존재하는 경우, 세관 파트너를 복구할 수 없음 */
  const hasCustomDutyAlready =
    accountPayableList.find(({ domain }) => domain === "customsDuty") &&
    restorePayableList.find(({ type }) => type === "customsDuty");

  /** 매입관리에 내륙운송사, 화물택배사가 존재하는 경우, 복구 불가능 */
  const hasInlandOrParcelAlready =
    accountPayableList.find(({ domain }) =>
      INLAND_DOMAIN_LIST.includes(domain)
    ) &&
    restorePayableList.find(({ type }) => INLAND_DOMAIN_LIST.includes(type));

  /** 세관 파트너를 두 개 이상 복구할 수 없음 */
  const isTryingRestoreOverOneCustomsDuty =
    restorePayableList.filter(({ type }) => type === "customsDuty").length > 1;

  const handleAccountPayableRestore = () => {
    if (hasCustomDutyAlready) {
      showSnackbar("이미 세관 파트너가 존재합니다.", "error");
      return;
    }

    if (hasInlandOrParcelAlready) {
      showSnackbar("이미 내륙운송사 혹은 화물택배사가 존재합니다.", "error");
      return;
    }

    if (isTryingRestoreOverOneCustomsDuty) {
      showSnackbar("세관 파트너는 하나만 복구할 수 있습니다.", "error");
      return;
    }

    if (!hasDestination) {
      showSnackbar(
        "내륙 운송사나 화물 택배사를 복구하려면 도착지가 필요합니다.",
        "error"
      );
      return;
    }

    const restoreIdList = restorePayableList.map(({ id }) => id);

    restorePayable(
      { accountPayableIds: restoreIdList },
      {
        onSuccess: () => {
          showSnackbar("성공적으로 복구했습니다.");
          setRestorePayableList([]);
          onClose();
          return queryClient.invalidateQueries(managementCardDetailQueryKey);
        },
      }
    );
  };

  const rowList = useMemo(() => {
    return deletedAccountPayableList.map((item) => ({
      select: (
        <Checkbox
          onChange={({ target: { checked } }) => {
            const { id, domain: type } = item;

            if (checked) {
              setRestorePayableList((prev) => [...prev, { id, type }]);
              return;
            }

            setRestorePayableList((prev) =>
              prev.filter(({ id }) => id !== item.id)
            );
          }}
        />
      ),
      partnerType: changePurchaseDomainToKr(item.domain),
      companyName: item.name,
      totalPrice: toThousandUnitFormat(item.totalPrice),
      vatPrice: toThousandUnitFormat(item.vatPrice),
      finalPrice: toThousandUnitFormat(item.finalPrice),
      createdAt: formatDate({ date: item.createdAt }),
      deletedAt: formatDate({ date: item.deletedAt }),
    }));
  }, [deletedAccountPayableList]);

  return (
    <>
      <Modal
        isOpened={isOpen}
        handleClose={onClose}
        modalBody={
          <Box display={"flex"} flexDirection={"column"} gap={2}>
            <Box display={"flex"} justifyContent={"space-between"}>
              <Typography variant="h6" className="sads" fontWeight={"bold"}>
                매입관리 복구 리스트
              </Typography>

              <Button
                className="sads"
                variant="contained"
                endIcon={<RestoreIcon />}
                disabled={!restorePayableList.length}
                onClick={handleAccountPayableRestore}
              >
                복구
              </Button>
            </Box>

            <Table
              showsTableVerticalLine
              headCells={HEAD_CELL_LIST}
              rows={rowList}
            />
          </Box>
        }
      />

      {ResponseHandlerOfGetDeletedAccountPayable}
      {ResponseHandlerOfRestoreAccountPayable}
    </>
  );
}
