import { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { red } from "@mui/material/colors";

import useSnackbar from "@sellernote/_shared/src/hooks/admin/useSnackbar";
import ADMIN_BID_QUERY, {
  ADMIN_BID_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_BID_QUERY";
import { BidStatus } from "@sellernote/_shared/src/types/forwarding/bid";
import { BID_STATUS_OPTION_LIST } from "@sellernote/_shared/src/utils/common/options";

import Modal from "../../../../components/Modal";

const ESTIMATING_AFTER_STATUS: BidStatus[] = [
  "waiting",
  "waitingForExporterInfo",
  "inProgress",
  "finished",
];

export default function ShipmentStatusSelect({
  shipmentId,
  isImport,
  status,
}: {
  shipmentId: number;
  isImport: boolean;
  status: BidStatus;
}) {
  const { handleSnackbarOpen } = useSnackbar();

  const queryClient = useQueryClient();

  const [opensModal, setOpensModal] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState<BidStatus>(status);

  const {
    mutate: changeBidDetail,
    ResponseHandler: ResponseHandlerOfChangeBidDetail,
  } = ADMIN_BID_QUERY.useChangeBidDetail(shipmentId);

  const {
    mutate: changeExportShipmentStatus,
    ResponseHandler: ResponseHandlerOfChangeExportShipmentStatus,
  } = ADMIN_BID_QUERY.useChangeExportShipmentStatus(shipmentId);

  const {
    mutate: changeBidStatusToWaitingForExporterInfo,
    ResponseHandler: ResponseHandlerOfChangeBidStatusToWaitingForExporterInfo,
  } = ADMIN_BID_QUERY.useChangeBidStatusToWaitingForExporterInfo(shipmentId);

  const {
    mutate: acceptExportShipment,
    ResponseHandler: ResponseHandlerOfAcceptExportShipment,
  } = ADMIN_BID_QUERY.useAcceptExportShipment(shipmentId);

  const getExportShipmentStatusDisabled = useCallback(
    (selectStatus: BidStatus | "all") => {
      const defaultDisabledStatus =
        selectStatus === "committed" ||
        selectStatus === "waiting" ||
        selectStatus === "waitingForExporterInfo";

      if (status === "committed" || status === "estimating") {
        if (defaultDisabledStatus || selectStatus === "inProgress") {
          return true;
        }
        return false;
      }

      if (defaultDisabledStatus) {
        return true;
      }
      return false;
    },
    [status]
  );

  const statusSelectDisabled = useCallback(
    (selectStatus: BidStatus | "all") => {
      const defaultDisabledStatus =
        selectStatus === "committed" ||
        selectStatus === "waiting" ||
        selectStatus === "inProgress";

      if (status === "committed" || status === "estimating") {
        if (
          defaultDisabledStatus ||
          selectStatus === "waitingForExporterInfo"
        ) {
          return true;
        }
        return false;
      }

      if (defaultDisabledStatus) {
        return true;
      }
      return false;
    },
    [status]
  );

  // 현재 상태가 견적 산출 중 이후인 경우에는 견적 산출 중으로 회귀 불가능 (선택 불가)
  const isEstimatingDisabled = (currentStatus: BidStatus) =>
    ESTIMATING_AFTER_STATUS.includes(currentStatus);

  const getStatusDisabled = (optionValue: BidStatus | "all") =>
    isImport
      ? statusSelectDisabled(optionValue)
      : getExportShipmentStatusDisabled(optionValue);

  const disableBidStatusOption = (optionValue: BidStatus | "all") => {
    if (optionValue === "estimating" && isEstimatingDisabled(status)) {
      return true;
    }

    return getStatusDisabled(optionValue);
  };

  const handleShipmentStatusChangeToCancel = () => {
    const cancelRequestResponseHandler = {
      onSuccess: () => {
        handleSnackbarOpen("의뢰 취소로 변경했습니다.");
        setOpensModal(false);
        queryClient.invalidateQueries(
          ADMIN_BID_QUERY_KEY_GEN.getAdminBidDetail({
            bidId: shipmentId,
          })
        );
      },

      onError: () => {
        handleSnackbarOpen("요청에 실패했습니다.", "error");
      },
    };

    if (isImport) {
      changeBidDetail(
        {
          status: "finished",
          projectStatus: "canceled",
        },
        {
          ...cancelRequestResponseHandler,
        }
      );
      return;
    }

    changeExportShipmentStatus(
      {
        status: "finished",
        projectStatus: "canceled",
      },
      {
        ...cancelRequestResponseHandler,
      }
    );
  };

  const handleShipmentStatusChangeToWaitingForExporterInfo = () => {
    changeBidStatusToWaitingForExporterInfo(
      {},
      {
        onSuccess: () => {
          handleSnackbarOpen("수출자 정보 입력 대기중으로 변경했습니다.");
          setOpensModal(false);
          queryClient.invalidateQueries(
            ADMIN_BID_QUERY_KEY_GEN.getAdminBidDetail({
              bidId: shipmentId,
            })
          );
        },

        onError: (error) => {
          if (error.code === "E086") {
            handleSnackbarOpen("이미 견적이 수락된 의뢰입니다.", "error");
            return;
          }
          handleSnackbarOpen("요청에 실패했습니다.", "error");
        },
      }
    );
  };

  const handleExportationShipmentStatusToInprogress = () => {
    acceptExportShipment(
      {},
      {
        onSuccess: () => {
          handleSnackbarOpen("의뢰를 진행 중으로 변경했습니다.");
          setOpensModal(false);
          queryClient.invalidateQueries(
            ADMIN_BID_QUERY_KEY_GEN.getAdminBidDetail({
              bidId: shipmentId,
            })
          );
        },

        onError: () => {
          handleSnackbarOpen("요청에 실패했습니다.", "error");
        },
      }
    );
  };

  const handleShipmentStatusChangeSelect = (status: BidStatus) => {
    setSelectedStatus(status);
    if (["inProgress", "waitingForExporterInfo", "finished"].includes(status)) {
      setOpensModal(true);
      return;
    }

    const responseHandler = {
      onSuccess: () => {
        handleSnackbarOpen("의뢰 상태를 변경했습니다.");
        queryClient.invalidateQueries(
          ADMIN_BID_QUERY_KEY_GEN.getAdminBidDetail({
            bidId: shipmentId,
          })
        );
      },

      onError: () => handleSnackbarOpen("요청에 실패했습니다.", "error"),
    };

    if (isImport) {
      changeBidDetail({ status }, { ...responseHandler });
      return;
    }

    changeExportShipmentStatus({ status }, { ...responseHandler });
    return;
  };

  const StatusChangeModalBody = ({
    message,
    onConfirm,
    isLoading,
  }: {
    message: string;
    onConfirm: () => void;
    isLoading?: boolean;
  }) => (
    <Box sx={{ display: "flex", flexDirection: "column", rowGap: "24px" }}>
      <Typography className="sads" sx={{ color: red[700] }}>
        {message}
      </Typography>

      <Box
        sx={{ display: "flex", justifyContent: "center", columnGap: "8px" }}
        display={"flex"}
        justifyContent={"center"}
      >
        <Button
          variant="outlined"
          className="sads"
          onClick={() => setOpensModal(false)}
        >
          아니오
        </Button>

        <Button
          variant="contained"
          color="primary"
          className="sads"
          disabled={isLoading}
          onClick={onConfirm}
        >
          {isLoading ? <CircularProgress size={25} /> : "예"}
        </Button>
      </Box>
    </Box>
  );

  return (
    <>
      <FormControl size="small" sx={{ width: 200 }}>
        <Select
          sx={{
            "& legend": { display: "none" },
            "& fieldset": { top: 0 },
          }}
          value={status}
          className="sads"
          onChange={(e) =>
            handleShipmentStatusChangeSelect(e.target.value as BidStatus)
          }
        >
          {BID_STATUS_OPTION_LIST.map((option) => (
            <MenuItem
              disabled={disableBidStatusOption(option.value)}
              key={option.value}
              hidden={option.value === "all"}
              value={option.value}
              className="sads"
            >
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {opensModal && (
        <Modal
          isOpened={opensModal}
          handleClose={() => setOpensModal(false)}
          modalBody={
            selectedStatus === "inProgress" ? (
              <StatusChangeModalBody
                message="제출한 견적서를 수락 처리 하시겠습니까?"
                onConfirm={handleExportationShipmentStatusToInprogress}
              />
            ) : selectedStatus === "waitingForExporterInfo" ? (
              <StatusChangeModalBody
                message="제출한 견적서를 수락 처리 하시겠습니까?"
                onConfirm={handleShipmentStatusChangeToWaitingForExporterInfo}
              />
            ) : selectedStatus === "finished" ? (
              <StatusChangeModalBody
                message="이 운임을 완전종료하시겠습니까?"
                onConfirm={handleShipmentStatusChangeToCancel}
              />
            ) : (
              <></>
            )
          }
        />
      )}

      {ResponseHandlerOfChangeBidDetail}
      {ResponseHandlerOfChangeExportShipmentStatus}
      {ResponseHandlerOfChangeBidStatusToWaitingForExporterInfo}
      {ResponseHandlerOfAcceptExportShipment}
    </>
  );
}
