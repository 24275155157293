import { useCallback, useEffect, useState } from "react";
import { Box } from "@mui/material";

import FileViewer from "@sellernote/_shared/src/admin-ui/containers/FileName/FilePreview/FileViewer";
import useFileUrl from "@sellernote/_shared/src/hooks/admin/useFileUrl";
import ADMIN_FILE_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_FILE_QUERY";
import { ForwardingAdminAttachment } from "@sellernote/_shared/src/types/forwarding/trello";

export default function PreviewContent({
  previewContent,
  fileExtension,
  pdfScale,
  imageWidth,
}: {
  previewContent: ForwardingAdminAttachment | undefined;
  fileExtension: string | undefined;
  pdfScale: number;
  imageWidth: number;
}) {
  const [showsPreview, setShowsPreview] = useState(false);
  const [s3Url, setS3Url] = useState("");

  const { mutate: getFileDownloadUrl } =
    ADMIN_FILE_QUERY.useGetFileDownloadUrl();

  const showPreview = useCallback(
    ({ downloadKey }: { downloadKey: string }) => {
      if (!downloadKey) return;

      getFileDownloadUrl(
        {
          pathParams: {
            key: downloadKey,
          },
        },
        {
          onSuccess: ({ data }) => {
            setS3Url(data.url);
            setShowsPreview(true);
          },
        }
      );
    },
    [getFileDownloadUrl]
  );

  /**
   * 미리보기가 이벤트가 아닌 렌더링 상태(onMount)에 의존하기 때문에 useEffect 사용
   */
  useEffect(() => {
    if (!previewContent?.key) return;

    showPreview({ downloadKey: previewContent.key });
  }, [previewContent?.key, showPreview]);

  const { fileUrl, isPdf, isHwp, hwpRenderDivRef } = useFileUrl(
    s3Url,
    fileExtension ?? "pdf"
  );

  return (
    <Box display={"flex"} justifyContent={"center"}>
      {showsPreview && (
        <FileViewer
          isHwp={isHwp}
          isPdf={isPdf}
          fileExtension={fileExtension ?? "pdf"}
          imageWidth={imageWidth}
          fileUrl={fileUrl}
          hwpRenderDivRef={hwpRenderDivRef}
          handleDocumentLoadSuccess={() => {}}
          pdfScale={pdfScale}
          pageNumber={1}
        />
      )}
    </Box>
  );
}
