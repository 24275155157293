import { Box, Button, Pagination, Typography } from "@mui/material";

import FileViewer from "@sellernote/_shared/src/admin-ui/containers/FileName/FilePreview/FileViewer";
import { ForwardingAdminAttachment } from "@sellernote/_shared/src/types/forwarding/trello";

import useFilePreview from "../../_hooks/useFilePreview";

function FilePreview({
  attachmentFileList,
}: {
  attachmentFileList: ForwardingAdminAttachment[] | undefined;
}) {
  const {
    fileUrl,
    isPdf,
    isHwp,
    hwpRenderDivRef,
    handleFileIndexChange,
    fileExtension,
    isPreviousButtonDisabled,
    isNextButtonDisabled,
    handlePdfFilePaginationChange,
    pdfFilePageNumber,
    handleDocumentLoadSuccess,
    pdfFilePageCount,
    ciAndPlAttachmentFile,
  } = useFilePreview({ attachmentFileList });

  const commonBoxStyle = {
    position: "sticky" as const,
    backgroundColor: "white",
    zIndex: 1,
    p: 2,
  };

  return (
    <Box
      sx={{
        position: "relative",
        height: "100%",
        display: "flex",

        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          ...commonBoxStyle,
          top: 0,
          borderBottom: "1px solid #ddd",
        }}
      >
        <Typography variant="h6" component="div">
          CI_PL 미리보기
        </Typography>
      </Box>

      {ciAndPlAttachmentFile ? (
        <Box
          sx={{
            overflow: "auto",
          }}
        >
          <FileViewer
            isHwp={isHwp}
            isPdf={isPdf}
            fileExtension={fileExtension ?? "pdf"}
            imageWidth={100}
            fileUrl={fileUrl}
            hwpRenderDivRef={hwpRenderDivRef}
            handleDocumentLoadSuccess={handleDocumentLoadSuccess}
            pdfScale={1.4}
            pageNumber={pdfFilePageNumber}
            iframeHeight={700}
          />
        </Box>
      ) : (
        <Box padding={2}>
          <Typography color="error">
            업로드 된 CI_PL 파일이 없습니다.
          </Typography>
        </Box>
      )}

      <Box
        sx={{
          ...commonBoxStyle,
          bottom: 0,
          borderTop: "1px solid #ddd",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          <Button
            aria-label="previous-image"
            onClick={() => handleFileIndexChange("decrement")}
            disabled={isPreviousButtonDisabled}
          >
            이전 파일
          </Button>

          <Button
            aria-label="next-image"
            onClick={() => handleFileIndexChange("increment")}
            disabled={isNextButtonDisabled}
          >
            다음 파일
          </Button>
        </Box>

        {isPdf && (
          <Pagination
            size="small"
            onChange={handlePdfFilePaginationChange}
            page={pdfFilePageNumber}
            count={pdfFilePageCount !== null ? pdfFilePageCount : 0}
          />
        )}
      </Box>
    </Box>
  );
}

export default FilePreview;
