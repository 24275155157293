import styled from "styled-components";

import checkbox from "../../../../images/checkbox/ckbx.svg";
const container = styled.div`
  > .title {
    margin-left: 16px;
    margin-bottom: 24px;
    font-weight: bold;
  }

  > .button-wrapper {
    text-align: center;
    margin-top: 40px;
  }

  .comment-container {
    display: flex;

    > .label {
      width: 45px;
    }
  }

  .table {
    .ant-table-thead {
      tr {
        th {
          color: #5482d6;
          font-weight: 700;
        }
      }
    }

    .checkbox {
      .ant-checkbox .ant-checkbox-inner {
        width: 16px;
        height: 16px;
      }
      .ant-checkbox-checked .ant-checkbox-inner {
        width: 16px;
        height: 16px;
        background: url(${checkbox}) no-repeat 50% 50% / cover;
        border: none;
      }
    }
  }
`;

export default {
  container,
};
