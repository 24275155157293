import { APP_NAME } from "@sellernote/_shared/src/constants";

const HEAD_CELL_LIST = (() => {
  if (APP_NAME === "partner-admin") {
    return [
      {
        id: "partnerName",
        disablePadding: false,
        label: "회사명",
        width: 100,
      },
      {
        id: "fileName",
        disablePadding: false,
        label: "파일 이름",
        width: 300,
      },
      {
        id: "totalPrice",
        disablePadding: false,
        label: "공급액",
        width: 100,
        numeric: true,
      },
      {
        id: "vatPrice",
        disablePadding: false,
        label: "부가세",
        width: 100,
        numeric: true,
      },
      {
        id: "finalPrice",
        disablePadding: false,
        label: "합계",
        width: 100,
        numeric: true,
      },
      {
        id: "isRequest",
        disablePadding: false,
        label: "출금요청",
        width: 100,
      },
      {
        id: "isWithdrawn",
        disablePadding: false,
        label: "출금완료",
        width: 100,
      },
    ];
  }
  return [
    {
      id: "partnerName",
      disablePadding: false,
      label: "회사명",
      width: 100,
    },
    {
      id: "isForeignWithdrawalTarget",
      disablePadding: false,
      label: "정산 대상",
      width: 100,
    },
    {
      id: "fileName",
      disablePadding: false,
      label: "파일 이름",
      width: 200,
    },
    {
      id: "purchaseRequest",
      disablePadding: false,
      label: "출금액",
      width: 100,
    },
    {
      id: "totalPrice",
      disablePadding: false,
      label: "공급액",
      width: 100,
    },
    {
      id: "vatPrice",
      disablePadding: false,
      label: "부가세",
      width: 100,
    },
    {
      id: "finalPrice",
      disablePadding: false,
      label: "합계",
      width: 100,
    },
    {
      id: "isRequest",
      disablePadding: false,
      label: "출금요청",
      width: 100,
    },
    {
      id: "isWithdrawn",
      disablePadding: false,
      label: "출금완료",
      width: 100,
    },
    {
      id: "withdrawalDate",
      disablePadding: false,
      label: "출금일시",
      width: 100,
    },
    {
      id: "delete",
      disablePadding: false,
      label: "삭제",
      width: 80,
    },
  ];
})();

export default HEAD_CELL_LIST;
