import { Dispatch, SetStateAction, useMemo } from "react";
import { Button } from "@mui/material";

import { GET_CARGO_MANIFEST_RES as CargoManifestInfoProps } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/trello";
import useSnackbar from "@sellernote/_shared/src/hooks/admin/useSnackbar";
import { ContainerTypeForCustoms } from "@sellernote/_shared/src/types/forwarding/bid";

import validateFormAndShowSnackbar from "../utils";
import useSaveCargoManifest from "./hooks";

export default function EditAndSaveButton({
  isEditMode,
  setIsEditMode,
  shipmentId,
  form,
  cargoManifestInfo,
  canSubmit,
  isImport,
  deletedIdList,
  setDeletedIdList,
  validateContainerTypeForCustoms,
}: {
  isEditMode: boolean;
  /** 함수형 업데이트를 위해 SetStateAction 타입 사용 */
  setIsEditMode: Dispatch<SetStateAction<boolean>>;
  shipmentId: number;
  form: CargoManifestInfoProps;
  cargoManifestInfo: CargoManifestInfoProps;
  canSubmit: boolean;
  isImport: boolean;
  deletedIdList: number[];
  setDeletedIdList: (val: number[]) => void;
  validateContainerTypeForCustoms: (
    list: (ContainerTypeForCustoms | null)[]
  ) => boolean;
}) {
  const { handleSnackbarOpen: showSnackbar } = useSnackbar();

  const containerTypeForCustomsList = useMemo(
    () => form.containers?.map((v) => v.containerTypeForCustoms) ?? [],
    [form.containers]
  );

  const {
    updateCargoManifest,
    ResponseHandlerOfDeleteContainerData,
    ResponseHandlerOfUpdateCargoManifest,
  } = useSaveCargoManifest({
    form,
    cargoManifestInfo,
    shipmentId,
    isImport,
    isEditMode,
    setIsEditMode,
    deletedIdList,
    setDeletedIdList,
  });

  const handleCargoManifestInfoUpdate = () => {
    if (
      isEditMode &&
      !validateContainerTypeForCustoms(containerTypeForCustomsList)
    ) {
      showSnackbar("입력한 세관 Code를 확인해주세요.", "error");
      return;
    }

    if (
      isEditMode &&
      !validateFormAndShowSnackbar({ form, showSnackbar, isImport })
    ) {
      return;
    }

    updateCargoManifest();
  };

  return (
    <>
      <Button
        variant="contained"
        sx={{
          background: "coral",
          "&.MuiButtonBase-root:hover": { background: "coral" },
        }}
        onClick={handleCargoManifestInfoUpdate}
        size="small"
        disabled={!canSubmit}
      >
        {isEditMode ? "저장" : "수정"}
      </Button>

      {ResponseHandlerOfDeleteContainerData}
      {ResponseHandlerOfUpdateCargoManifest}
    </>
  );
}
