import { Dispatch, SetStateAction, useCallback, useState } from "react";
import { Button, Input, Modal } from "antd";

import useSnackbar from "@sellernote/_shared/src/hooks/admin/useSnackbar";
import TRELLO_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/TRELLO_BID_QUERY";

const RejectSettlementModal = ({
  showRejectCommentModal,
  setShowRejectCommentModal,
  bidId,
}: {
  showRejectCommentModal: boolean;
  setShowRejectCommentModal: Dispatch<SetStateAction<boolean>>;
  bidId: number;
}) => {
  const { handleSnackbarOpen } = useSnackbar();

  const [rejectComment, setRejectComment] = useState("");

  const {
    mutate: confirmSettlement,
    ResponseHandler: ResponseHandlerOfConfirmSettlement,
  } = TRELLO_BID_QUERY.useConfirmSettlement({
    bidId,
  });

  const handleRejectSettlementClick = useCallback(() => {
    confirmSettlement(
      {
        acceptSettlement: false,
        settlementRejectionReason: rejectComment,
      },
      {
        onSuccess: () => {
          handleSnackbarOpen("정산 반려를 신청했습니다.");
          return;
        },

        onError: () => {
          handleSnackbarOpen("요청에 실패했습니다.", "error");
          return;
        },
      }
    );
  }, [confirmSettlement, handleSnackbarOpen, rejectComment]);

  return (
    <>
      <Modal
        width={400}
        title="반려 코멘트 작성"
        destroyOnClose={true}
        visible={showRejectCommentModal}
        onCancel={() => setShowRejectCommentModal(false)}
        footer={[
          <Button key="back" onClick={() => setShowRejectCommentModal(false)}>
            돌아가기
          </Button>,
        ]}
      >
        <div>코멘트</div>

        <Input
          value={rejectComment}
          onChange={(e) => setRejectComment(e.target.value)}
        ></Input>

        <Button
          className="reject-settlement"
          onClick={handleRejectSettlementClick}
        >
          반려
        </Button>
      </Modal>

      {ResponseHandlerOfConfirmSettlement}
    </>
  );
};

export default RejectSettlementModal;
