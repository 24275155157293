import { useMemo } from "react";
import { useAtomValue } from "jotai";

import useForwardingManagerIdFilter from "@sellernote/_shared/src/hooks/admin/useForwardingManagerIdFilter";
import { FORWARDING_ADMIN_BID_JOTAI_ATOMS } from "@sellernote/_shared/src/jotaiStates/bid";

import useFreightAndIncotermsHeadFilter from "./useFreightAndIncotermsHeadFilter";

export default function useShipmentTableHeadFilter() {
  const filterData = useAtomValue(
    FORWARDING_ADMIN_BID_JOTAI_ATOMS.SHIPMENT_TABLE_FILTER_LIST
  );

  const {
    ForwardingManagerFilter,
    headFilterData: ForwardingManagerIdFilterData,
    handleReset: ForwardingManagerIdReset,
  } = useForwardingManagerIdFilter(filterData.forwardingManagerIdList);

  const {
    FreightAndIncotermsHeadFilter,
    headFilterData: FreightAndIncotermsFilterData,
    handleReset: FreightAndIncotermsReset,
  } = useFreightAndIncotermsHeadFilter(filterData);

  const tableHeadFilterData = useMemo(() => {
    return {
      ...ForwardingManagerIdFilterData,
      ...FreightAndIncotermsFilterData,
    };
  }, [FreightAndIncotermsFilterData, ForwardingManagerIdFilterData]);

  const filterDataReset = () => {
    ForwardingManagerIdReset();
    FreightAndIncotermsReset();
  };

  const ShipmentTableHeadFilter = {
    ForwardingManagerFilter: ForwardingManagerFilter,
    FreightAndIncotermsHeadFilter: FreightAndIncotermsHeadFilter,
  };

  return {
    ShipmentTableHeadFilter,
    tableHeadFilterData,
    filterDataReset,
  };
}
