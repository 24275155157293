import { useState } from "react";
import { useQueryClient } from "react-query";

import useSnackbar from "@sellernote/_shared/src/hooks/admin/useSnackbar";
import TRELLO_BID_QUERY, {
  TRELLO_BID_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/admin/TRELLO_BID_QUERY";

export default function useHandleScheduleMail({
  shipmentId,
  changeReasonByAdmin,
  scheduleChangeReason,
  setScheduleChangeReason,
  isScheduleChangedByAdmin,
}: {
  shipmentId: number;
  changeReasonByAdmin: string | null;
  scheduleChangeReason: string;
  setScheduleChangeReason: (val: string) => void;
  isScheduleChangedByAdmin: boolean;
}) {
  const { handleSnackbarOpen } = useSnackbar();

  const queryClient = useQueryClient();

  const [opensModalForMail, setOpensMailForMail] = useState(false);

  const handleSuccess = () => {
    /** 성공시 스낵바 노출 */
    handleSnackbarOpen("성공적으로 메일을 발송했습니다.", "success");
    setScheduleChangeReason("");
    setOpensMailForMail(false);

    return Promise.all([
      queryClient.invalidateQueries(
        TRELLO_BID_QUERY_KEY_GEN.getShipmentScheduleDetail({ shipmentId })
      ),
      queryClient.invalidateQueries(TRELLO_BID_QUERY_KEY_GEN.trelloDetail()),
    ]);
  };

  const { mutate: sendScheduleMail } = TRELLO_BID_QUERY.useSendScheduleMail({
    shipmentId,
    onSuccess: handleSuccess,
  });

  const handleScheduleMailSend = () => {
    const hasChangeReason = typeof changeReasonByAdmin === "string";

    if (isScheduleChangedByAdmin) {
      handleSnackbarOpen("변경된 스케줄을 먼저 저장해주세요.", "warning");
      return;
    }

    if (hasChangeReason) {
      sendScheduleMail({ scheduleChangeReason: changeReasonByAdmin });
      return;
    }

    setOpensMailForMail(true);
  };

  const handleScheduleMailSendWithReason = () => {
    sendScheduleMail({ scheduleChangeReason });
  };

  return {
    opensModalForMail,
    setOpensMailForMail,

    handleScheduleMailSend,
    handleScheduleMailSendWithReason,
  };
}
