import { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import { Button, Grid, Typography } from "@mui/material";

import Modal from "../../../../../../components/Modal";

function ChangeCustomsPartnerModal({
  opensChangeCustomsPartnerModal,
  setOpensChangeCustomsPartnerModal,
  handleChangeCustomsPartnerToShipper,
  bidAccountPayableId,
}: {
  opensChangeCustomsPartnerModal: boolean;
  setOpensChangeCustomsPartnerModal: Dispatch<SetStateAction<boolean>>;
  handleChangeCustomsPartnerToShipper: (accountPayableId: number) => void;
  bidAccountPayableId: number;
}) {
  const handleModalClose = useCallback(() => {
    setOpensChangeCustomsPartnerModal(false);
  }, [setOpensChangeCustomsPartnerModal]);

  const ModalBody = useMemo(() => {
    return (
      <>
        <Typography variant="h6" component="div">
          화주 발송으로 변경하시겠습니까?
        </Typography>

        <Typography variant="subtitle2" component="div">
          통관서류 및 거래명세서가 고객 이메일로 발송됩니다.
        </Typography>

        <Grid container spacing={2} sx={{ marginTop: 1, maxWidth: 400 }}>
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                handleChangeCustomsPartnerToShipper(bidAccountPayableId);
                handleModalClose();
              }}
            >
              예
            </Button>
          </Grid>

          <Grid item xs={6}>
            <Button
              variant="contained"
              color="success"
              onClick={handleModalClose}
            >
              아니오
            </Button>
          </Grid>
        </Grid>
      </>
    );
  }, [
    bidAccountPayableId,
    handleChangeCustomsPartnerToShipper,
    handleModalClose,
  ]);

  return (
    <>
      <Modal
        isOpened={opensChangeCustomsPartnerModal}
        handleClose={handleModalClose}
        modalBody={ModalBody}
      />
    </>
  );
}

export default ChangeCustomsPartnerModal;
