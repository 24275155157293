import { useCallback } from "react";
import { useQueryClient } from "react-query";
import { Edit, Save } from "@mui/icons-material";
import { Box, Button } from "@mui/material";

import useSnackbar from "@sellernote/_shared/src/hooks/admin/useSnackbar";
import TRELLO_BID_QUERY, {
  TRELLO_BID_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/admin/TRELLO_BID_QUERY";
import { ContainerTypeForCustoms } from "@sellernote/_shared/src/types/forwarding/bid";

import { ContainerInfo } from "../types";
import {
  validateContainerNo,
  validateContainerType,
  validateWeight,
} from "./utils/validation";

import useDeleteFormContainer from "../../../../_hooks/container/useDeleteFormContainer";
import useGetAndValidateContainerType from "../../../../_hooks/container/useGetAndValidateContainerType";
const CONTAINER_UPDATE_REQUEST_TYPE = "trello";

export default function ContainerSaveButton({
  shipmentId,
  containerList,
  deletedIdList,
  setDeletedIdList,
}: {
  shipmentId: number;
  containerList: ContainerInfo[];
  deletedIdList: number[];
  setDeletedIdList: (val: number[]) => void;
}) {
  const { handleSnackbarOpen } = useSnackbar();
  const queryClient = useQueryClient();

  const { validateContainerTypeForCustoms } = useGetAndValidateContainerType();

  const {
    deleteContainer: deleteContainerAndUpdateContainer,
    ResponseHandlerOfDeleteContainerData,
  } = useDeleteFormContainer({
    onSuccess: () => {
      setDeletedIdList([]);
      updateContainer();
    },
  });

  const {
    mutate: updateContainerInfo,
    ResponseHandler:
      ResponseHandlerOfUpdateOperationManagementCardContainerInfo,
  } = TRELLO_BID_QUERY.useUpdateOperationManagementCardContainerInfo({
    onSuccess: () => {
      handleSnackbarOpen("컨테이너 정보가 저장되었습니다.");

      return Promise.all([
        queryClient.invalidateQueries(
          TRELLO_BID_QUERY_KEY_GEN.getOperationManagementCardDetailContainerInfo(
            { shipmentId }
          )
        ),

        queryClient.invalidateQueries(
          TRELLO_BID_QUERY_KEY_GEN.getHBLInfo({ shipmentId })
        ),
      ]);
    },
  });

  const validateContainerList = (list: ContainerInfo[]) => {
    if (!list.length) return true;

    const containerTypeForCustomsList = list.map(
      ({ containerTypeForCustoms }) => containerTypeForCustoms
    );

    const checkList = [
      {
        isValid: validateContainerNo(list),
        message: "컨테이너 번호가 11자리인지 확인해주세요.",
      },
      {
        isValid: validateContainerType(list),
        message: "컨테이너 타입을 확인해주세요.",
      },
      {
        isValid: validateContainerTypeForCustoms(containerTypeForCustomsList),
        message: "입력된 세관 코드가 맞는지 확인해주세요.",
      },
      {
        isValid: validateWeight(list),
        message: "무게는 소수점 3자리를 초과할 수 없습니다.",
      },
    ];

    const failedValidation = checkList.find(({ isValid }) => !isValid);

    if (failedValidation) {
      handleSnackbarOpen(failedValidation.message, "error");
      return false;
    }

    return true;
  };

  const updateContainer = useCallback(() => {
    /**
     * containerTypeForCustoms에 대한 Type narrowing
     *   - 해당 함수를 실행하기 전, 유효성 검사를 하기 때문에 containerTypeForCustoms가 null인 경우는 존재하지 않음
     */
    const validContainerList = containerList.filter(
      (
        container
      ): container is ContainerInfo & {
        containerTypeForCustoms: ContainerTypeForCustoms;
      } => {
        return container.containerTypeForCustoms !== null;
      }
    );

    /** 컨테이너 데이터 형식을 요청 Payload에 맞춰 변환 */
    const formattedContainerList = validContainerList.map((container) => {
      const { id, tempId, isClientAdded, ...containerData } = container;

      /** 클라이언트에서 추가한 컨테이너는 id, tempId, isClientAdded 제외 */
      if (isClientAdded) {
        return containerData;
      }

      return { id: Number(id), ...containerData };
    });

    updateContainerInfo({
      shipmentId,
      reqType: CONTAINER_UPDATE_REQUEST_TYPE,
      containers: formattedContainerList,
    });
  }, [updateContainerInfo, shipmentId, containerList]);

  const handleContainerSave = () => {
    if (!validateContainerList(containerList)) return;

    /** 삭제된 컨테이너가 있는 경우, 삭제 후 컨테이너 정보 업데이트 */
    if (deletedIdList.length) {
      deleteContainerAndUpdateContainer({ idList: deletedIdList });
      return;
    }

    updateContainer();
  };

  const hasContainer = containerList.length > 0;

  return (
    <Box display={"flex"} justifyContent={"flex-end"} mt={2}>
      <Button
        variant="contained"
        color="primary"
        className="sads"
        endIcon={hasContainer ? <Edit /> : <Save />}
        onClick={handleContainerSave}
      >
        {hasContainer ? "수정" : "저장"}
      </Button>

      {ResponseHandlerOfDeleteContainerData}
      {ResponseHandlerOfUpdateOperationManagementCardContainerInfo}
    </Box>
  );
}
