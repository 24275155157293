import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import { APP_NAME } from "@sellernote/_shared/src/constants";
import {
  FreightType,
  Liner,
} from "@sellernote/_shared/src/types/common/common";
import { BidProjectStatus } from "@sellernote/_shared/src/types/forwarding/bid";
import { TrelloBidManagement } from "@sellernote/_shared/src/types/forwarding/trello";
import { checkIfHaveAuthorityToChangeTrelloDetail } from "@sellernote/_shared/src/utils/forwarding/admin/trello";

import useUpdateBLnumberAndLinerLogic from "./useUpdateBLnumberAndLinerLogic";

const isShipdaAdmin = APP_NAME === "shipda-admin";

const UpdateBLNumberAndLiner = ({
  management,
  projectStatus,
  freightType,
  linerData,
  BLType,
  isExportation,
}: {
  management: TrelloBidManagement;
  projectStatus: BidProjectStatus;
  freightType: FreightType;
  linerData: Liner[];
  BLType: string;
  isExportation: boolean;
}) => {
  const {
    BLTypeSelect,
    handleCheckPointUpdate,
    isCommonInputDisabled,
    selectedValueOfBLType,
    MBL,
    HBL,
    handleMBLInputBlur,
    setMBL,
    setHBL,
    shipNameList,
    showsLinerSelect,
    setLinerId,
    isEditMode,
    authority,
    setIsEditMode,
    handleClickUpdateButton,
    handleUnipassTracking,
    ResponseHandlerOfUpdateBlNumber,
    ResponseHandlerOfUpdateOpCheckPoint,
    ResponseHandlerOfTrackingUnipass,
    ResponseHandlerOfTrackingUnipassContainerNumber,
    linerId,
    getLinerName,
    getRequestButtonDisabled,
    isBLTypeSelectDisabled,
    checkIfRequestButtonCanBeRendered,
  } = useUpdateBLnumberAndLinerLogic({
    management,
    projectStatus,
    freightType,
    linerData,
    isExportation,
  });

  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item xs={1}>
        <Typography variant="subtitle1" component="span">
          {freightType === "AIR" ? "AWB 입력하기" : "BL 입력하기"}:
        </Typography>
      </Grid>
      <Grid>
        <Box sx={{ display: "flex", mt: 1 }}>
          {BLTypeSelect}

          {isShipdaAdmin && (
            <FormControl fullWidth size="small" sx={{ ml: 1 }}>
              <InputLabel>BL 유형</InputLabel>

              <Select
                value={BLType}
                label="BL 유형"
                onChange={({ target }) => handleCheckPointUpdate(target.value)}
                sx={{ width: 80 }}
                disabled={isBLTypeSelectDisabled}
              >
                <MenuItem value={"surrender"}>SBL</MenuItem>
                <MenuItem value={"original"}>OBL</MenuItem>
              </Select>
            </FormControl>
          )}
        </Box>
      </Grid>
      {selectedValueOfBLType === "ALL" && (
        <Grid item xs={2}>
          <TextField
            fullWidth
            label={freightType === "AIR" ? "MAWB" : "MBL"}
            size="small"
            value={MBL}
            disabled={isCommonInputDisabled}
            onBlur={handleMBLInputBlur}
            onChange={(e) => setMBL(e.target.value)}
          />
        </Grid>
      )}

      {selectedValueOfBLType === "ALL" && (
        <Grid item xs={2}>
          <TextField
            fullWidth
            size="small"
            label={freightType === "AIR" ? "HAWB" : "HBL"}
            value={HBL}
            disabled={isCommonInputDisabled}
            onChange={(e) => setHBL(e.target.value)}
          />
        </Grid>
      )}

      {selectedValueOfBLType === "HBL" && (
        <Grid item xs={4}>
          <TextField
            fullWidth
            size="small"
            label={"HBL"}
            value={HBL}
            disabled={isCommonInputDisabled}
            onChange={(e) => setHBL(e.target.value)}
          />
        </Grid>
      )}

      {(selectedValueOfBLType === "DirectMBL" ||
        selectedValueOfBLType === "MBL") && (
        <Grid item xs={4}>
          <TextField
            fullWidth
            label={
              freightType !== "AIR" ? selectedValueOfBLType : "MAWB DIRECT"
            }
            size="small"
            value={MBL}
            disabled={isCommonInputDisabled}
            onBlur={handleMBLInputBlur}
            onChange={(e) => setMBL(e.target.value)}
          />
        </Grid>
      )}
      {showsLinerSelect && (
        <Grid item container xs={2.5}>
          <Grid item xs={10}>
            <Autocomplete
              disabled={
                !management.fullETD || !isShipdaAdmin || isCommonInputDisabled
              }
              size="small"
              options={shipNameList}
              value={
                linerId
                  ? {
                      value: linerId,
                      label: getLinerName(linerId),
                    }
                  : null
              }
              onChange={(
                event,
                newValue: { value: number | null; label: string } | null
              ) => {
                setLinerId(newValue?.value);
              }}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={freightType === "AIR" ? "항공사명" : "선사명"}
                />
              )}
            />
          </Grid>
        </Grid>
      )}

      {isShipdaAdmin && (
        <Grid item sx={{ display: "flex", columnGap: "8px" }}>
          {(management.hBL || management.mBL) && !isEditMode && (
            <Button
              variant="contained"
              disabled={checkIfHaveAuthorityToChangeTrelloDetail(
                authority,
                projectStatus
              )}
              onClick={() => setIsEditMode(true)}
            >
              수정
            </Button>
          )}

          {checkIfRequestButtonCanBeRendered() && (
            <Button
              variant="contained"
              disabled={
                checkIfHaveAuthorityToChangeTrelloDetail(
                  authority,
                  projectStatus
                ) || getRequestButtonDisabled()
              }
              onClick={() => {
                handleClickUpdateButton();
              }}
            >
              완료
            </Button>
          )}

          <Button
            variant="contained"
            color="success"
            disabled={
              !management.BLType ||
              checkIfHaveAuthorityToChangeTrelloDetail(authority, projectStatus)
            }
            onClick={() => handleUnipassTracking()}
          >
            관세청 갱신
          </Button>
        </Grid>
      )}

      {ResponseHandlerOfUpdateBlNumber}
      {ResponseHandlerOfUpdateOpCheckPoint}
      {ResponseHandlerOfTrackingUnipass}
      {ResponseHandlerOfTrackingUnipassContainerNumber}
    </Grid>
  );
};

export default UpdateBLNumberAndLiner;
