import { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { Box, Button, Chip, SxProps, Theme, Typography } from "@mui/material";
import { useSetRecoilState } from "recoil";

import { APP_NAME } from "@sellernote/_shared/src/constants";
import useSnackbar from "@sellernote/_shared/src/hooks/admin/useSnackbar";
import ADMIN_USER_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_USER_QUERY";
import { FORWARDING_INVOICE_ATOMS } from "@sellernote/_shared/src/states/forwarding/invoice";
import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { AdminBidDetail } from "@sellernote/_shared/src/types/forwarding/adminBid";
import { BID_STATUS_OPTION_LIST } from "@sellernote/_shared/src/utils/common/options";
import { checkIsSettlement } from "@sellernote/_shared/src/utils/forwarding/admin/trello";
import {
  changeBidProjectStatusNameToKr,
  changeExportProjectStatusNameToKr,
  checkHasTrelloCard,
} from "@sellernote/_shared/src/utils/forwarding/bid";

import ManagerHistory from "./ManagerHistory";
import ShipmentStatusSelect from "./ShipmentStatusSelect";

const boxLayoutStyle: SxProps<Theme> = {
  display: "flex",
  alignItems: "center",
  gap: "4px",
  "> p:first-of-type": {
    color: COLOR.bk_60,
  },
};

const isShipdaAdmin = APP_NAME === "shipda-admin";

const BasicDesc = ({ bidDetail }: { bidDetail: AdminBidDetail }) => {
  const { handleSnackbarOpen } = useSnackbar();

  const setTrelloCardId = useSetRecoilState(
    FORWARDING_INVOICE_ATOMS.ADMIN_TRELLO_CARD_ID
  );

  const history = useHistory();

  const { adminUserList = [] } = ADMIN_USER_QUERY.useGetAdminUserList();

  const managerName = (() => {
    if (!bidDetail.team) return "-";

    const targetId = bidDetail.isImport
      ? bidDetail.team.forwardingManagerId
      : bidDetail.team.exportForwardingManagerId;

    const manager = adminUserList?.find(({ id }) => id === targetId);

    return manager?.name ?? "-";
  })();

  const originalShipmentId = useMemo(
    () => bidDetail.management.comments[0]?.originalShipmentId,
    [bidDetail.management.comments]
  );

  const handleMoveToTrelloPageClick = () => {
    if (checkHasTrelloCard(bidDetail.projectStatus)) {
      setTrelloCardId(bidDetail.id);

      if (
        checkIsSettlement(bidDetail.projectStatus) &&
        APP_NAME === "shipda-admin"
      ) {
        history.push("/settlement");
        return;
      }

      if (bidDetail.serviceType === "consolidation") {
        history.push("/trello/consolidation");
        return;
      }

      // 수출의뢰 일때는 운영 관리 > 수출화물로 이동
      if (!bidDetail.isImport) {
        history.push("/trello/export");
        return;
      }

      history.push("/trello/general");
      return;
    }

    handleSnackbarOpen("운영 관리 카드가 없는 의뢰입니다.", "warning");
    return;
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        rowGap: "16px",
        flexGrow: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          columnGap: "24px",
        }}
      >
        <Box sx={boxLayoutStyle}>
          <Typography className="sads">의뢰번호:</Typography>

          <Button
            variant="text"
            className="sads"
            sx={{ userSelect: "text" }}
            onClick={handleMoveToTrelloPageClick}
          >
            {bidDetail.id}
          </Button>
        </Box>

        {isShipdaAdmin && (
          <>
            {originalShipmentId && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "12px",
                }}
              >
                <Chip
                  label="재주문"
                  variant="outlined"
                  color="info"
                  size="small"
                />

                <Typography
                  className="sads"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  (원 의뢰번호:
                  <Button
                    variant="text"
                    size="small"
                    className="sads"
                    sx={{ userSelect: "text" }}
                    onClick={() => {
                      history.push(`/bid/detail/${originalShipmentId}`);
                    }}
                  >
                    {originalShipmentId}
                  </Button>
                  )
                </Typography>
              </Box>
            )}
            <Box sx={boxLayoutStyle}>
              <Typography className="sads">PO번호:</Typography>

              {bidDetail.poMapping?.length
                ? bidDetail.poMapping
                    .filter(({ purchaseOrder }) => purchaseOrder)
                    .map(({ poId, purchaseOrder }) => (
                      <Button
                        key={poId}
                        variant="text"
                        className="sads"
                        sx={{ userSelect: "text" }}
                        onClick={() => {
                          history.push(`/order/${poId}`);
                        }}
                      >
                        {purchaseOrder.poNumber}
                      </Button>
                    ))
                : "-"}
            </Box>
          </>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", columnGap: "24px" }}>
          <Box sx={boxLayoutStyle}>
            <Typography className="sads">상태:</Typography>

            {APP_NAME === "shipda-admin" ? (
              <ShipmentStatusSelect
                shipmentId={bidDetail.id}
                isImport={bidDetail.isImport}
                status={bidDetail.status}
              />
            ) : (
              <Typography className="sads">
                {BID_STATUS_OPTION_LIST.find(
                  ({ value }) => value === bidDetail.status
                )?.label ?? ""}
              </Typography>
            )}
          </Box>

          <Box sx={boxLayoutStyle}>
            <Typography className="sads">세부 상태:</Typography>

            <Typography className="sads">
              {bidDetail.isImport
                ? changeBidProjectStatusNameToKr(bidDetail.projectStatus) || "-"
                : changeExportProjectStatusNameToKr(bidDetail.projectStatus) ||
                  "-"}
            </Typography>
          </Box>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center", columnGap: "24px" }}>
          <Box sx={boxLayoutStyle}>
            <Typography className="sads">담당자: </Typography>

            <Typography className="sads">{managerName}</Typography>

            <ManagerHistory
              teamId={bidDetail.team.id}
              shipmentId={bidDetail.id}
              adminUserList={adminUserList}
            />
          </Box>

          <Box sx={boxLayoutStyle}>
            <Typography className="sads">의뢰 생성일:</Typography>

            <Typography className="sads">
              {new Date(bidDetail.createdAt).toLocaleDateString()}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default BasicDesc;
