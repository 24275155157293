import { useFormContext } from "react-hook-form";
import { Box, Button, CircularProgress, Typography } from "@mui/material";

import useSnackbar from "@sellernote/_shared/src/hooks/admin/useSnackbar";
import {
  HBLContainerInfo,
  HBLInfo,
} from "@sellernote/_shared/src/types/forwarding/trello";

import useSaveHBLRequest from "../../../../_hooks/useSaveHBLRequest";
import useSendHBLEmailRequest from "../../../../_hooks/useSendHBLEmailRequest";
import SendHBLEmailModal from "./SendHBLEmailModal";

function RequestButton({
  shipmentId,
  deletedContainerIdList,
  setDeletedContainerIdList,
  containerList,
}: {
  shipmentId: number;
  deletedContainerIdList: number[];
  setDeletedContainerIdList: (idList: number[]) => void;
  containerList: HBLContainerInfo[];
}) {
  const { handleSnackbarOpen } = useSnackbar();

  const {
    handleTemporarySaveClick,
    handleSaveClick,
    isTempSaveRequestLoading,
    isSaveRequestLoading,
    ResponseHandlerOfSaveHBL,
    ResponseHandlerOfTempSaveHBL,
  } = useSaveHBLRequest({
    shipmentId,
    deletedContainerIdList,
    setDeletedContainerIdList,
  });

  const {
    handleHBLEmailSend,
    showsSendHBLEmailModal,
    setShowsSendHBLEmailModal,
    sendEmailType,
    handleSendHBlEmailModalOpen,
    isTemporarilySaved,
    isSendHBLEmailRequestLoading,
    ResponseHandler: ResponseHandlerOfSendHBLEmail,
  } = useSendHBLEmailRequest({ shipmentId });

  const { handleSubmit } = useFormContext<HBLInfo>();

  const hasContainer = containerList.length > 0;

  return (
    <Box
      display={"flex"}
      justifyContent={isTemporarilySaved ? "space-between" : "flex-end"}
      width={"100%"}
      alignItems={"center"}
    >
      {isTemporarilySaved && (
        <Typography color="error" variant="body2">
          *임시저장된 상태입니다.
        </Typography>
      )}

      <Box display={"flex"} justifyContent={"flex-end"} gap={1}>
        <Button
          color="info"
          variant="contained"
          size="small"
          onClick={handleTemporarySaveClick}
          disabled={isTempSaveRequestLoading}
        >
          {isTempSaveRequestLoading ? (
            <CircularProgress size={25} />
          ) : (
            "임시저장"
          )}
        </Button>

        <Button
          color="primary"
          variant="contained"
          size="small"
          // 저장 시에는 필수값을 검증하기 위해 handleSubmit을 추가
          onClick={handleSubmit(handleSaveClick)}
          disabled={isSaveRequestLoading}
        >
          {isSaveRequestLoading ? <CircularProgress size={25} /> : "저장"}
        </Button>

        <Button
          color="success"
          variant="contained"
          size="small"
          onClick={() => {
            if (!hasContainer) {
              handleSnackbarOpen("최소 컨테이너 1대가 필요합니다.", "error");
              return;
            }

            handleSendHBlEmailModalOpen("check");
          }}
        >
          Check BL 발송
        </Button>

        <Button
          color="success"
          variant="contained"
          size="small"
          onClick={() => {
            if (!hasContainer) {
              handleSnackbarOpen("최소 컨테이너 1대가 필요합니다.", "error");
              return;
            }

            handleSendHBlEmailModalOpen("confirm");
          }}
        >
          최종 BL 발송
        </Button>
      </Box>

      <SendHBLEmailModal
        showsSendHBLEmailModal={showsSendHBLEmailModal}
        setShowsSendHBLEmailModal={setShowsSendHBLEmailModal}
        onHBLEmailSend={handleHBLEmailSend}
        sendEmailType={sendEmailType}
        isSendHBLEmailRequestLoading={isSendHBLEmailRequestLoading}
      />

      {ResponseHandlerOfSaveHBL}
      {ResponseHandlerOfTempSaveHBL}
      {ResponseHandlerOfSendHBLEmail}
    </Box>
  );
}

export default RequestButton;
