import { useEffect, useState } from "react";
import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";

import { FreeTimeError } from "@sellernote/_shared/src/api-interfaces/shipda-api/freeTime";
import TRELLO_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/TRELLO_BID_QUERY";
import { formatDate } from "@sellernote/_shared/src/utils/common/date";

import FreeTimeTable from "../../../../../../components/FreeTimeTable";

import ClearCautionForLFD from "./ClearCautionForLFD";

const FreeTime = ({ shipmentId }: { shipmentId: number }) => {
  const [errorInfo, setErrorInfo] = useState<FreeTimeError>();

  const [showsFreeTimeTable, setShowsFreeTimeTable] = useState(false);

  const handleAccordionToggle = () => setShowsFreeTimeTable((prev) => !prev);

  const {
    data: freeTime,
    ResponseHandler: ResponseHandlerOfGetTrelloFreeTime,
  } = TRELLO_BID_QUERY.useGetTrelloFreeTime({
    shipmentId,
    enabled: !!shipmentId,
    onError: (failureInfo) => {
      if (!failureInfo) return;

      setErrorInfo({
        errorCode: failureInfo.errorCode,
        error: failureInfo.error,
      });
    },
    hidesFailureModal: true,
  });

  const freeTimeUpdatedAt = formatDate({
    date: freeTime?.updatedAt,
    type: "YY_MM_DD_HH_mm_ss",
  });

  /**
   * 운영관리 카드 상세 Mount시, 프리타임 Accordion의 기본 열림/닫힘 상태가 데이터에 의존함.
   *  - freeTime 데이터가 있으면 기본 열림 상태
   *  - freeTime 데이터가 없으면 기본 닫힘 상태
   */
  useEffect(() => {
    setShowsFreeTimeTable(Boolean(freeTime));
  }, [freeTime]);

  return (
    <>
      <Accordion
        className="sads"
        expanded={showsFreeTimeTable}
        onChange={handleAccordionToggle}
        sx={{ mt: 2 }}
      >
        <AccordionSummary expandIcon={<ExpandMore />} className="sads">
          프리타임
        </AccordionSummary>

        <AccordionDetails className="sads">
          <Box display={"flex"} flexDirection={"column"} gap={2}>
            {freeTime && (
              <Box ml={"auto"} display={"flex"} alignItems={"center"} gap={2}>
                <Typography className="sads">
                  최종 업데이트: {freeTimeUpdatedAt}
                </Typography>

                <ClearCautionForLFD
                  shipmentId={shipmentId}
                  isClearedCautionForLFD={freeTime.isFreeTimeChecking}
                />
              </Box>
            )}

            {showsFreeTimeTable && (
              <FreeTimeTable
                containers={freeTime?.containers}
                isCombined={freeTime?.isCombined}
                errorInfo={errorInfo}
              />
            )}
          </Box>
        </AccordionDetails>
      </Accordion>

      {ResponseHandlerOfGetTrelloFreeTime}
    </>
  );
};

export default FreeTime;
