import { useState } from "react";
import { CloudUpload } from "@mui/icons-material";
import { Box } from "@mui/material";

import UploadModal from "@sellernote/_shared/src/admin-ui/containers/UploadModal";

export default function UploadField({
  shipmentId,
  bidAccountPayableId,
  invoiceType,
}: {
  shipmentId: number;
  bidAccountPayableId: number;
  invoiceType: string;
}) {
  const [showsUploadModal, setShowsUploadModal] = useState(false);

  const handleModalOpen = () => setShowsUploadModal(true);
  const handleModalClose = () => setShowsUploadModal(false);

  return (
    <>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        border={"1px dashed #1976d2"}
        borderRadius={4}
        width={"100%"}
        height={300}
        onClick={handleModalOpen}
      >
        <CloudUpload color="primary" sx={{ fontSize: 50 }} />
      </Box>

      <UploadModal
        showsUploadModal={showsUploadModal}
        setShowsUploadModal={handleModalClose}
        type={invoiceType}
        id={shipmentId}
        bidAccountPayableId={bidAccountPayableId}
        dataType={"trello"}
        isPermission={true}
        isMultiple={false}
      />
    </>
  );
}
