import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useQueryClient } from "react-query";

import useSnackbar from "@sellernote/_shared/src/hooks/admin/useSnackbar";
import TRELLO_BID_QUERY, {
  TRELLO_BID_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/admin/TRELLO_BID_QUERY";
import { HBLInfo } from "@sellernote/_shared/src/types/forwarding/trello";

export type SendHBLEmailType = "check" | "confirm";

function useSendEmailRequest({ shipmentId }: { shipmentId: number }) {
  const [showsSendHBLEmailModal, setShowsSendHBLEmailModal] = useState(false);
  const [sendEmailType, setSendEmailType] = useState<SendHBLEmailType>("check");

  const {
    mutate: sendHBLEmail,
    isLoading: isSendHBLEmailRequestLoading,
    ResponseHandler: ResponseHandlerOfSendHBLEmail,
  } = TRELLO_BID_QUERY.useSendHBLEmail({
    shipmentId,
  });

  const { handleSnackbarOpen } = useSnackbar();

  const queryClient = useQueryClient();

  const { watch } = useFormContext<HBLInfo>();

  const blType = watch("blType");
  const tempSavedAt = watch("tempSavedAt");

  const handleHBLEmailSend = () => {
    if (!blType) {
      handleSnackbarOpen("blType이 저장되지 않았습니다. ", "warning");
      return;
    }

    if (tempSavedAt) {
      handleSnackbarOpen("저장 후 발송 버튼을 누르세요. ", "warning");
      return;
    }

    if (sendEmailType === "check" && blType !== "CHECK") {
      handleSnackbarOpen("BLType이 CHECK가 아닙니다. ", "warning");
      return;
    }

    if (sendEmailType === "confirm" && blType === "CHECK") {
      handleSnackbarOpen(
        "BLType이 CHECK일 때는 최종 BL를 발행할 수 없습니다.",
        "warning"
      );
      return;
    }
    sendHBLEmail(
      { blType },
      {
        onSuccess: () => {
          handleSnackbarOpen("요청에 성공했습니다.");
          queryClient.invalidateQueries(
            TRELLO_BID_QUERY_KEY_GEN.getHBLInfo({ shipmentId })
          );
        },

        onError: ({ response }) => {
          const errorMessage = response?.data?.error;

          switch (errorMessage) {
            case "임시 저장된 BL은 메일을 발송할 수 없습니다.":
            case "최종 BL은 체크 BL 발송 이후에 요청이 가능합니다.":
            case "BL 발행을 위한 해당 의뢰의 정보가 존재하지 않습니다.":
              handleSnackbarOpen(errorMessage, "error");
              break;
            default:
              handleSnackbarOpen("요청에 실패했습니다.", "error");
          }
        },
      }
    );
  };

  const handleSendHBlEmailModalOpen = (HBLType: "check" | "confirm") => {
    setSendEmailType(HBLType);
    setShowsSendHBLEmailModal(true);
  };

  const isTemporarilySaved = Boolean(watch("tempSavedAt"));

  return {
    handleHBLEmailSend,
    showsSendHBLEmailModal,
    setShowsSendHBLEmailModal,
    sendEmailType,
    handleSendHBlEmailModalOpen,
    isTemporarilySaved,
    isSendHBLEmailRequestLoading,
    ResponseHandler: <>{ResponseHandlerOfSendHBLEmail}</>,
  };
}

export default useSendEmailRequest;
