import { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useIsFetching, useQueryClient } from "react-query";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

import useSnackbar from "@sellernote/_shared/src/hooks/admin/useSnackbar";
import TRELLO_BID_QUERY, {
  TRELLO_BID_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/admin/TRELLO_BID_QUERY";
import { InlnadTransportType } from "@sellernote/_shared/src/types/common/common";
import { PartnerListItem } from "@sellernote/_shared/src/types/forwarding/partner";
import { TrelloBidDetail } from "@sellernote/_shared/src/types/forwarding/trello";
import { INLAND_PARTNER_OPTION_LIST } from "@sellernote/_shared/src/utils/common/options";
import { getInlandPartnerName } from "@sellernote/_shared/src/utils/forwarding/admin/trello";

interface FormValues {
  inlandType: InlnadTransportType;
  driverName?: string;
  driverPhone?: string;
  truckNum?: string;
  companyName: string;
  parcelNum?: string;
}

const InlandPartnerForm = ({
  trelloDetail,
  bidAccountPayableId,
  partnerList,
  onClose,
}: {
  trelloDetail: TrelloBidDetail;
  bidAccountPayableId: number;
  partnerList?: PartnerListItem[];
  onClose?: () => void;
}) => {
  const { handleSnackbarOpen } = useSnackbar();

  const queryClient = useQueryClient();

  const isInvalidating =
    useIsFetching(TRELLO_BID_QUERY_KEY_GEN.trelloDetail()) > 0;

  const formDefaultValues = useMemo(() => {
    const { management } = trelloDetail;
    const inlandType = trelloDetail.inlandType || "sole";
    const companyName = getInlandPartnerName(trelloDetail.accountPayables);

    return {
      inlandType,
      companyName,
      ...(inlandType === "parcel"
        ? { parcelNum: management.parcelNum || undefined }
        : {
            driverName: management.driverName || undefined,
            driverPhone: management.driverPhone || undefined,
            truckNum: management.truckNum || undefined,
          }),
    };
  }, [trelloDetail]);

  const {
    control,
    watch,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: formDefaultValues,
  });
  const [inputValue, setInputValue] = useState("");
  const inlandType = watch("inlandType");

  useEffect(() => {
    reset(formDefaultValues);
  }, [trelloDetail, reset, formDefaultValues]);

  const {
    mutate: registerInlandTransport,
    ResponseHandler: ResponseHandlerOfUseRegisterInlandTransport,
  } = TRELLO_BID_QUERY.useRegisterInlandTransport(trelloDetail.id);

  const {
    mutate: cancelInlandTransport,
    ResponseHandler: ResponseHandlerOfUseCancelInlandTransport,
  } = TRELLO_BID_QUERY.useCancelInlandTransport(trelloDetail.id);

  const inlandPartnerBusinessArea = useMemo(() => {
    if (inlandType === "parcel") {
      return "parcel";
    }
    return "inland";
  }, [inlandType]);

  const inlandPartnerList = useMemo(() => {
    return (
      partnerList
        ?.filter((v: PartnerListItem) => {
          return v.businessArea === inlandPartnerBusinessArea;
        })
        .map(({ name }: PartnerListItem) => {
          return name;
        }) ?? []
    );
  }, [inlandPartnerBusinessArea, partnerList]);

  const changePartnerNameToId = useCallback(
    (companyName: string) => {
      const findPartner = partnerList?.find((v: PartnerListItem) => {
        return (
          v.name === companyName && v.businessArea === inlandPartnerBusinessArea
        );
      });

      if (!findPartner) {
        return;
      }
      return findPartner.id;
    },
    [inlandPartnerBusinessArea, partnerList]
  );

  const handleInlandPartnerRegister = useCallback(
    (formData: FormValues) => {
      registerInlandTransport(
        {
          ...formData,
          companyId: changePartnerNameToId(formData.companyName),
          detailFlag: true,
          bidAccountPayableId,
        },
        {
          onSuccess: async () => {
            handleSnackbarOpen("내륙 운송 유형을 등록했습니다.");
            await queryClient.invalidateQueries(
              TRELLO_BID_QUERY_KEY_GEN.trelloDetail()
            );
            onClose?.();
          },

          onError: () => {
            handleSnackbarOpen("요청에 실패했습니다.", "error");
          },
        }
      );
    },
    [
      registerInlandTransport,
      changePartnerNameToId,
      bidAccountPayableId,
      queryClient,
      handleSnackbarOpen,
      onClose,
    ]
  );

  const handleInlandTransportCancel = useCallback(() => {
    cancelInlandTransport(
      {
        inlandFlag: false,
        bidAccountPayableId,
      },
      {
        onSuccess: async () => {
          handleSnackbarOpen("내륙 운송을 취소했습니다.");
          await queryClient.invalidateQueries(
            TRELLO_BID_QUERY_KEY_GEN.trelloDetail()
          );
          onClose?.();
        },

        onError: () => {
          handleSnackbarOpen("요청에 실패했습니다.", "error");
        },
      }
    );
  }, [
    cancelInlandTransport,
    bidAccountPayableId,
    handleSnackbarOpen,
    queryClient,
    onClose,
  ]);

  return (
    <>
      <Box
        component="form"
        onSubmit={handleSubmit(handleInlandPartnerRegister)}
      >
        <FormControl fullWidth>
          <InputLabel id="select-inland-type">내륙운송 유형</InputLabel>
          <Controller
            name="inlandType"
            control={control}
            defaultValue="sole"
            render={({ field }) => (
              <Select
                {...field}
                variant="outlined"
                labelId="select-inland-type"
                label="내륙운송 유형"
              >
                {INLAND_PARTNER_OPTION_LIST.map((v) => (
                  <MenuItem
                    key={v.value}
                    value={v.value}
                    onClick={() => {
                      setValue("driverName", "");
                      setValue("driverPhone", "");
                      setValue("truckNum", "");
                      setValue("companyName", "");
                      setValue("parcelNum", "");
                    }}
                  >
                    {v.label}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>
        {inlandType !== "parcel" && (
          <>
            <Controller
              name="driverName"
              control={control}
              rules={{ required: "필수 값입니다." }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="기사명"
                  fullWidth
                  margin="normal"
                  error={!!errors.driverName}
                  helperText={errors.driverName?.message}
                />
              )}
            />

            <Controller
              name="driverPhone"
              control={control}
              rules={{ required: "필수 값입니다." }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="연락처"
                  fullWidth
                  margin="normal"
                  error={!!errors.driverPhone}
                  helperText={errors.driverPhone?.message}
                />
              )}
            />

            <Controller
              name="truckNum"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="차량번호"
                  fullWidth
                  margin="normal"
                />
              )}
            />
          </>
        )}

        <Controller
          name="companyName"
          control={control}
          rules={{ required: "필수 값입니다." }}
          render={({ field }) => (
            <Autocomplete
              {...field}
              options={inlandPartnerList}
              value={
                inlandPartnerList.find((value) => value === field.value) || null
              }
              onChange={(_, newValue) => {
                field.onChange(newValue || "");
                setInputValue(newValue || "");
              }}
              onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
              disableClearable={field.value === ""}
              inputValue={inputValue}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={inlandType === "parcel" ? "택배사" : "내륙운송사"}
                  fullWidth
                  margin="normal"
                  error={!!errors.companyName}
                  helperText={errors.companyName?.message}
                />
              )}
            />
          )}
        />

        {inlandType === "parcel" && (
          <Controller
            name="parcelNum"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="송장번호"
                fullWidth
                margin="normal"
              />
            )}
          />
        )}

        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          disabled={isInvalidating}
          sx={{ mt: 2 }}
        >
          저장
        </Button>
        <Button
          variant="outlined"
          color="error"
          fullWidth
          disabled={isInvalidating}
          sx={{ mt: 2 }}
          onClick={handleInlandTransportCancel}
        >
          배차취소
        </Button>
      </Box>

      {ResponseHandlerOfUseRegisterInlandTransport}
      {ResponseHandlerOfUseCancelInlandTransport}
    </>
  );
};

export default InlandPartnerForm;
