import { Box, SxProps, Theme, Typography } from "@mui/material";

import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { AdminBidDetail } from "@sellernote/_shared/src/types/forwarding/adminBid";
import { getCountryCodeKR } from "@sellernote/_shared/src/utils/common/country";
import { changeServiceTypeToNameKr } from "@sellernote/_shared/src/utils/forwarding/admin/businessManagement";
import { changeTransPortTypeToKr } from "@sellernote/_shared/src/utils/forwarding/admin/trello";

const boxLayoutStyle: SxProps<Theme> = {
  display: "flex",
  alignItems: "flex-start",
  gap: "4px",
  "> p:first-of-type": {
    color: COLOR.bk_60,
  },
};

const boxGridStyle: SxProps<Theme> = {
  display: "grid",
  gridTemplateColumns: "repeat(5, 1fr)",
  gap: "4px",
};

const TransportDesc = ({ bidDetail }: { bidDetail: AdminBidDetail }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <Box sx={boxGridStyle}>
        <Box sx={boxLayoutStyle}>
          <Typography className="sads">유형:</Typography>

          <Typography className="sads">
            {changeServiceTypeToNameKr(bidDetail.serviceType)}(
            {bidDetail.isImport ? "수입" : "수출"})
          </Typography>
        </Box>

        <Box sx={boxLayoutStyle}>
          <Typography className="sads">국가:</Typography>

          <Typography className="sads">
            {getCountryCodeKR(bidDetail.startCountry)}
          </Typography>
        </Box>

        <Box sx={boxLayoutStyle}>
          <Typography className="sads">인코텀즈:</Typography>

          <Typography className="sads">{bidDetail.incoterms}</Typography>
        </Box>
      </Box>

      {bidDetail.serviceType === "consolidation" ? (
        <>
          <Box sx={boxGridStyle}>
            <Box sx={boxLayoutStyle}>
              <Typography className="sads">창고명:</Typography>

              <Typography className="sads">
                {bidDetail.wareHouse.name || "-"}
              </Typography>
            </Box>

            <Box sx={boxLayoutStyle}>
              <Typography className="sads">창고 담당자명:</Typography>

              <Typography className="sads">
                {bidDetail.wareHouse.managerName || "-"}
              </Typography>
            </Box>

            <Box sx={boxLayoutStyle}>
              <Typography className="sads">계산 기준:</Typography>

              <Typography className="sads">
                1CBM/{bidDetail.wareHouse.cbm}KG
              </Typography>
            </Box>

            <Box sx={boxLayoutStyle}>
              <Typography className="sads">연락처:</Typography>

              <Typography className="sads">
                {bidDetail.wareHouse.managerPhone || "-"}
              </Typography>
            </Box>

            <Box sx={boxLayoutStyle}>
              <Typography className="sads">이메일:</Typography>

              <Typography className="sads">
                {bidDetail.wareHouse.managerEmail || "-"}
              </Typography>
            </Box>
          </Box>

          <Box sx={boxGridStyle}>
            <Box sx={boxLayoutStyle}>
              <Typography className="sads">도착지 주소:</Typography>
              <Typography className="sads">
                {bidDetail.endAddress || "-"}
              </Typography>
            </Box>

            <Box sx={{ ...boxLayoutStyle, gridColumn: "span 2" }}>
              <Typography className="sads">도착지 상세주소:</Typography>
              <Typography className="sads">
                {bidDetail.endAddressDetail || "-"}
              </Typography>
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Box sx={boxGridStyle}>
            <Box sx={boxLayoutStyle}>
              <Typography className="sads">출발지 유형:</Typography>

              <Typography className="sads">
                {changeTransPortTypeToKr(bidDetail.startType)}
              </Typography>
            </Box>

            {bidDetail.startType === "inland" ? (
              <>
                <Box sx={boxLayoutStyle}>
                  <Typography className="sads">출발지 항구/공항:</Typography>

                  <Typography className="sads">
                    {bidDetail.startViaPort?.nameEN || "-"}
                  </Typography>
                </Box>

                <Box sx={boxLayoutStyle}>
                  <Typography className="sads">출발지 주소:</Typography>

                  <Typography className="sads">
                    {bidDetail.startAddress || "-"}
                  </Typography>
                </Box>

                {!bidDetail.isImport && (
                  <Box sx={{ ...boxLayoutStyle, gridColumn: "span 2" }}>
                    <Typography className="sads">출발지 상세주소:</Typography>

                    <Typography className="sads">
                      {bidDetail.startAddressDetail || "-"}
                    </Typography>
                  </Box>
                )}
              </>
            ) : (
              <Box sx={boxLayoutStyle}>
                <Typography className="sads">출발지 항구/공항:</Typography>

                <Typography className="sads">
                  {bidDetail.startPort?.nameEN || "-"}
                </Typography>
              </Box>
            )}
          </Box>

          <Box sx={boxGridStyle}>
            <Box sx={boxLayoutStyle}>
              <Typography className="sads">도착지 유형:</Typography>

              <Typography className="sads">
                {changeTransPortTypeToKr(bidDetail.endType)}
              </Typography>
            </Box>

            {bidDetail.endType === "inland" ? (
              <>
                <Box sx={boxLayoutStyle}>
                  <Typography className="sads">도착지 항구/공항:</Typography>
                  <Typography className="sads">
                    {bidDetail.endViaPort?.nameEN || "-"}
                  </Typography>
                </Box>

                <Box sx={boxLayoutStyle}>
                  <Typography className="sads">도착지 주소:</Typography>
                  <Typography className="sads">
                    {bidDetail.endAddress || "-"}
                  </Typography>
                </Box>

                {bidDetail.isImport && (
                  <Box sx={{ ...boxLayoutStyle, gridColumn: "span 2" }}>
                    <Typography className="sads">도착지 상세주소:</Typography>
                    <Typography className="sads">
                      {bidDetail.endAddressDetail || "-"}
                    </Typography>
                  </Box>
                )}
              </>
            ) : (
              <Box sx={boxLayoutStyle}>
                <Typography className="sads">도착지 항구/공항:</Typography>
                <Typography className="sads">
                  {bidDetail.endPort?.nameEN || "-"}
                </Typography>
              </Box>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default TransportDesc;
