import { Divider, Typography } from "@mui/material";
import { Box } from "@mui/material";
import { Tooltip } from "@mui/material";

import { FreightType } from "@sellernote/_shared/src/types/common/common";
import { TrelloBidManagement } from "@sellernote/_shared/src/types/forwarding/trello";

import {
  getBLInfoByFreightType,
  getBLTypeByFreightType,
} from "../../../../../../utils";

export default function BLTooltip({
  management,
  freightType,
}: {
  management: TrelloBidManagement;
  freightType: FreightType;
}) {
  const { BLType, mBL, hBL } = management;

  if (!freightType) return null;

  const BLTypeRecord = getBLTypeByFreightType(freightType);
  const BLInfoRecord = getBLInfoByFreightType({ freightType, mBL, hBL });

  return (
    <Tooltip
      title={
        <Box sx={{ p: 1, fontSize: 14, whiteSpace: "pre-line" }}>
          BL 유형: {BLTypeRecord[BLType] ?? ""}
          <Divider flexItem sx={{ mt: 1, mb: 1, background: "white" }} />
          {BLInfoRecord[BLType] ?? "입력된 BL 정보가 없습니다."}
        </Box>
      }
      arrow
    >
      <Typography sx={{ ml: 2, color: "#1976d2", fontWeight: 500 }}>
        [BL 정보]
      </Typography>
    </Tooltip>
  );
}
