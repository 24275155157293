import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Divider, IconButton, Tooltip } from "@mui/material";
import { useAtom } from "jotai";

import { FORWARDING_ADMIN_TRELLO_ATOMS } from "@sellernote/_shared/src/jotaiStates/trello";

export default function AddFormButton({
  index,
  showsAddFormButton,
  isFormDisabled,
}: {
  index: number;
  showsAddFormButton: boolean;
  isFormDisabled: boolean;
}) {
  const [shipmentScheduleList, setShipmentScheduleList] = useAtom(
    FORWARDING_ADMIN_TRELLO_ATOMS.SHIPMENT_SCHEDULE_LIST
  );

  const handleScheduleFormAdd = () => {
    if (!canCreateForm || isFormDisabled) return;

    /** 추가된 폼의 arrival 정보를 저장 */
    const previousScheduleArrivalPort =
      shipmentScheduleList[index]?.arrivalPort;

    const previousArrivalDate = shipmentScheduleList[index]?.ETA;

    const nextIndex = index + 1;

    const updatedList = [...shipmentScheduleList];

    /** 새로운 폼의 arrivalPort는 이전 index의 arrivalPort 정보를 가져옴 */
    const newForm = {
      departurePort: {
        id: 0,
        name: "",
        nameEN: "",
      },
      arrivalPort: previousScheduleArrivalPort,
      ETD: null,
      ATD: null,
      ATA: null,
      ETA: previousArrivalDate,
      shipIMO: 0,
      shipName: "",
      voyageNo: "",
    };

    /** 추가된 폼 이전 index의 arrival 정보는 초기화 */
    updatedList[index].arrivalPort = {
      id: 0,
      name: "",
      nameEN: "",
    };

    updatedList[index].ETA = null;

    /** 새로운 객체를 다음 index에 삽입 */
    updatedList.splice(nextIndex, 0, newForm);

    setShipmentScheduleList(updatedList);
  };

  /** 폼은 최대 5개까지 생성 가능 */
  const canCreateForm = shipmentScheduleList.length < 5;

  if (!showsAddFormButton) return null;

  return (
    <Divider
      style={{ marginTop: 5 }}
      sx={{
        "&::before, &::after": {
          borderColor: "#8e918f",
        },
      }}
    >
      <Tooltip
        title={
          !canCreateForm || isFormDisabled
            ? "이미 5개의 항목이 있거나, 운송이 완료된 의뢰입니다."
            : "항목 생성"
        }
      >
        <div>
          <IconButton
            aria-label="add-new-form"
            onClick={handleScheduleFormAdd}
            disabled={!canCreateForm || isFormDisabled}
          >
            <AddCircleIcon fontSize="large" />
          </IconButton>
        </div>
      </Tooltip>
    </Divider>
  );
}
