import { useMemo } from "react";

import SHIP_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/SHIP_QUERY";

function useShipNameOptionList() {
  const { data: shipNameData } = SHIP_QUERY.useGetShipList();

  const shipNameOptionList = useMemo(() => {
    const TBDOption = { IMO: null, value: "TBD", label: "TBD" };

    const optionList =
      shipNameData?.list.map(({ IMO, name }) => ({
        IMO: IMO,
        value: name,
        label: name,
      })) ?? [];

    return [TBDOption, ...optionList];
  }, [shipNameData?.list]);

  return {
    shipNameOptionList,
  };
}

export default useShipNameOptionList;
