import { Button, Grid, Typography } from "@mui/material";

import Modal from "../../../../../../../components/Modal";

function SettlementValidationModal({
  setShowsSettlementValidationModal,
  showsSettlementValidationModal,
  onSuccessButtonClick,
  hasDeposit,
}: {
  setShowsSettlementValidationModal: (value: boolean) => void;
  showsSettlementValidationModal: boolean;
  onSuccessButtonClick: () => void;
  hasDeposit: boolean;
}) {
  return (
    <Modal
      isOpened={showsSettlementValidationModal}
      handleClose={() => setShowsSettlementValidationModal(false)}
      modalBody={
        <Grid container spacing={4} direction="column">
          <Grid item container direction="column" alignItems={"center"}>
            <Grid item>
              <Typography variant="subtitle2" component="div">
                {hasDeposit
                  ? "입금완료가 아닌 거래내역이 있습니다."
                  : "입금내역을 등록하지 않은 거래명세서가 존재합니다."}
              </Typography>
            </Grid>

            {hasDeposit && (
              <Grid item>
                <Typography variant="subtitle2" component="div">
                  이대로 마감 요청 하시겠습니까??
                </Typography>
              </Grid>
            )}
          </Grid>

          {!hasDeposit && (
            <Grid item textAlign={"center"}>
              <Button onClick={() => setShowsSettlementValidationModal(false)}>
                확인
              </Button>
            </Grid>
          )}

          {hasDeposit && (
            <Grid container item justifyContent={"center"}>
              <Grid item>
                <Button
                  color="inherit"
                  onClick={() => setShowsSettlementValidationModal(false)}
                >
                  아니오
                </Button>
              </Grid>

              <Grid item>
                <Button color="primary" onClick={onSuccessButtonClick}>
                  예
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      }
    />
  );
}

export default SettlementValidationModal;
