import { useState } from "react";
import { useQueryClient } from "react-query";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";

import { SHIP_QUERY_KEY_GEN } from "@sellernote/_shared/src/queries/forwarding/admin/SHIP_QUERY";
import TRELLO_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/TRELLO_BID_QUERY";

import Modal from "../../../../../components/Modal";

const ManageShipNameModal = ({
  showsManageShipNameModal,
  setShowsManageShipNameModal,
}: {
  showsManageShipNameModal: boolean;
  setShowsManageShipNameModal: (value: boolean) => void;
}) => {
  const queryClient = useQueryClient();

  const [shipImo, setShipImo] = useState("");

  const {
    mutate: updateShipImo,
    ResponseHandler: ResponseHandlerOfUpdateShipImo,
  } = TRELLO_BID_QUERY.useUpdateShipImo({
    successModalInfo: {
      handleConfirmSuccess: (initQuery) => {
        initQuery();
        setShipImo("");
        queryClient.invalidateQueries(SHIP_QUERY_KEY_GEN.getShipList());
      },

      customizeMessage: (res) => {
        if (res?.newName) {
          return {
            messageType: "titleAndBody",
            title: "IMO 변경에 성공했습니다.",
            body: `선박명이 ${res.name}에서 ${res.newName}으로 변경되었습니다.`,
          };
        }

        return {
          messageType: "titleOnly",
          title: "IMO 등록에 성공했습니다.",
        };
      },
    },

    onError: () => {
      setShipImo("");
    },
  });

  return (
    <>
      <Modal
        isOpened={showsManageShipNameModal}
        handleClose={() => setShowsManageShipNameModal(false)}
        modalBody={
          <Box>
            <Typography align="center" variant="h5">
              선명 관리
            </Typography>

            <Typography align="center" variant="body1" sx={{ mb: 2, mt: 2 }}>
              SHIP ID(IMO)를 입력하여 추가해주세요.
            </Typography>

            <Grid container gap={2}>
              <Grid item>
                <TextField
                  size="small"
                  label="SHIP ID(IMO)"
                  type="number"
                  value={shipImo}
                  onChange={(e) => setShipImo(e.target.value)}
                  placeholder="숫자를 입력해 주세요"
                />
              </Grid>

              <Grid item>
                <Button
                  variant="contained"
                  onClick={() => updateShipImo({ IMO: Number(shipImo) })}
                >
                  선명 추가
                </Button>
              </Grid>
            </Grid>
          </Box>
        }
      />

      {ResponseHandlerOfUpdateShipImo}
    </>
  );
};

export default ManageShipNameModal;
