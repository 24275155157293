import { useCallback } from "react";

import useSnackbar from "@sellernote/_shared/src/hooks/admin/useSnackbar";
import TRELLO_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/TRELLO_BID_QUERY";
import {
  OpCheckPointIndexSignature,
  TrelloCardRenewOpCheckPointProps,
} from "@sellernote/_shared/src/types/forwarding/trello";

type RefreshTrelloListQueryData = {
  shipmentId: number;
  renewOpCheckPointData: OpCheckPointIndexSignature;
  isFinished: boolean;
  isImport: boolean;
};

/**
 * 운영 관리 카드 > OBL 수취, 발행 여부 & 적하목록 신고 체크박스 핸들링 훅
 * 수입, 수출 공용으로 사용함
 *
 * @param refreshTrelloList 데이터를 갱신하지 않고, 캐시 데이터를 변경
 */
function useHandleTrelloCardOpCheckPoint({
  refreshTrelloListForListPage,
}: {
  refreshTrelloListForListPage: ({
    shipmentId,
    renewOpCheckPointData,
    isFinished,
    isImport,
  }: RefreshTrelloListQueryData) => void;
}) {
  const { handleSnackbarOpen } = useSnackbar();

  const { mutate: updateOpCheckPoint } =
    TRELLO_BID_QUERY.useUpdateOpCheckPoint();

  const handleTrelloCardOpCheckPoint = useCallback(
    ({
      shipmentId,
      isChecked,
      key,
      isFinished,
      isImport,
    }: TrelloCardRenewOpCheckPointProps) => {
      const currentISOTime = new Date().toISOString();

      /** 요청 성공시 캐시에 접근, 데이터를 변경 */
      const onSuccess = () => {
        refreshTrelloListForListPage({
          shipmentId,
          renewOpCheckPointData: { [key]: isChecked ? null : currentISOTime },
          isFinished: Boolean(isFinished) === true,
          isImport: Boolean(isImport) === true,
        });

        handleSnackbarOpen("변경에 성공했습니다.");
      };

      updateOpCheckPoint(
        {
          [key]: isChecked ? null : currentISOTime,
          pathParams: { shipmentId },
        },
        { onSuccess: onSuccess }
      );
    },
    [handleSnackbarOpen, refreshTrelloListForListPage, updateOpCheckPoint]
  );

  return { handleTrelloCardOpCheckPoint };
}

export default useHandleTrelloCardOpCheckPoint;
